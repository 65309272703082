// src/components/NotFound.js
import React from 'react';
import '../styles/styles.css';
import '../styles/NotFound.css';
import notFoundImg from '../assets/404.gif';

const NotFound = () => {
  return (
    <div className="not-found-container">
      <img src={notFoundImg} alt="404 Not Found" className="not-found-image" />
      <h2>404 Not Found!</h2>
    </div>
  );
};

export default NotFound;
