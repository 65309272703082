import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { resolveEnsName } from '../utils/formatAddress'; // Import utility functions
import '../styles/Navigation.css';
import logo from '../assets/logo.png';
import axios from 'axios';

const Navigation = ({ web3 }) => {
  const [walletAddress, setWalletAddress] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Load wallet info from localStorage on component mount
  useEffect(() => {
    const savedAddress = localStorage.getItem('walletAddress');
    const savedDisplayName = localStorage.getItem('displayName');
    if (savedAddress) {
      setWalletAddress(savedAddress);
      setDisplayName(savedDisplayName || savedAddress);
    }
  }, []);

  const connectWallet = async () => {
    if (!web3) {
      console.log("Web3 is not available.");
      return;
    }

    try {
      setLoading(true); // Show loader during connection
      const accounts = await web3.eth.requestAccounts();
      if (accounts && accounts.length > 0) {
        const address = accounts[0];

        // Prompt the user to sign a message
        const message = `Mint Debut: Sign this message to confirm ownership of your wallet. Timestamp: ${new Date().toISOString()}`;
        try {
          const signature = await web3.eth.personal.sign(message, address, '');
          console.log("Signature received:", signature);

          // Update wallet details
          setWalletAddress(address);
          localStorage.setItem('walletAddress', address);

          const nameOrShort = await resolveEnsName(address); // Use ENS Public API
          setDisplayName(nameOrShort);
          localStorage.setItem('displayName', nameOrShort);

          console.log("Wallet connected:", nameOrShort);

          // Start fetching NFT data
          await axios.post('/api/connect-wallet', { wallet: address });
        } catch (signatureError) {
          console.error("User declined to sign the message:", signatureError);
          // Reset state if user cancels signing
          setWalletAddress('');
          setDisplayName('');
          localStorage.removeItem('walletAddress');
          localStorage.removeItem('displayName');
        }
      } else {
        console.log("No wallet connected.");
      }
    } catch (error) {
      console.error("Error connecting wallet:", error.message);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const disconnectWallet = () => {
    // Smoothly update the UI to show disconnected state
    setWalletAddress('');
    setDisplayName('');
    localStorage.removeItem('walletAddress');
    localStorage.removeItem('displayName');
    console.log("Wallet disconnected.");
  };

  return (
    <nav>
      <div className="logo">
        <NavLink to="/">
          <img src={logo} alt="Logo" />
        </NavLink>
      </div>
      <button className="nav-toggle" onClick={toggleMenu}>☰</button>
      <ul className={menuOpen ? 'active' : 'hidden'}>
        <li><NavLink to="/">Collections</NavLink></li>
        <li><NavLink to="/about">About</NavLink></li>
        <li>
          {loading ? (
            <p>Connecting...</p>
          ) : walletAddress ? (
            <div className="wallet-info">
              <NavLink to="/account" className="wallet-address">
                {displayName}
              </NavLink>
              <button className="disconnect-button" onClick={disconnectWallet}>
                Disconnect
              </button>
            </div>
          ) : (
            <button id="connect-wallet" onClick={connectWallet}>
              CONNECT WALLET
            </button>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
