// src/components/About.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/styles.css';
import '../styles/Navigation.css';
import aboutImg from '../assets/about.jpg';

const About = () => {
  return (
    <div className="container">
      <div className="page-content">
        <div className="page-content">
        <h1 class="page-title">About</h1>
        <p><img src={aboutImg} alt="About" className="about-image" /></p>
        <p>
            Mint Debut emerged from the intriguing notion of tracking the inaugural NFT collections of eminent brands and celebrities.
            As the digital realm burgeons, the days see a growing number of renowned brands, artists, and public figures launching
            their exclusive NFT collections.</p>
            <p>These genesis collections, in many instances, promise enduring benefits, potentially lifelong.
            Although the trajectory of Web 3 remains speculative, the permanence of blockchain is undeniable. Within this immutable digital
            ledger, these collections stand as historic firsts.</p>
            <p>At MintDebut, we envision a compelling utility potential in these collections,
            with the assurance of value retention through collectibility, even in adverse scenarios.
        </p>
        <p>
            Explore our curated list of collections <Link to="/">here</Link>.
        </p>
        </div>
      </div>
    </div>
  );
};

export default About;
