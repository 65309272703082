import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import Web3 from 'web3';
import Navigation from './components/Navigation';
import Home from './components/Home';
import About from './components/About';
import Account from './components/Account';
import NotFound from './components/NotFound';

const App = () => {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [signature, setSignature] = useState(null);

  useEffect(() => {
    if (window.ethereum) {
      const newWeb3 = new Web3(window.ethereum);
      setWeb3(newWeb3);
    } else if (window.web3) {
      const newWeb3 = new Web3(window.web3.currentProvider);
      setWeb3(newWeb3);
      console.log("Legacy Web3 provider detected.");
    } else {
      console.warn("No Ethereum provider found. Install MetaMask!");
    }
  
    // Restore wallet address and signature from localStorage
    const savedAccount = localStorage.getItem('walletAddress');
    if (savedAccount) {
      console.log("Restoring wallet from localStorage:", savedAccount);
      setAccount(savedAccount);
    }
  }, []);  

  const connectWallet = async () => {
    if (!web3) {
      console.error("Web3 is not initialized.");
      return;
    }
  
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const userAccount = accounts[0];
      console.log("Wallet connected:", userAccount);
  
      // Generate a message for the user to sign
      const message = `Mint Debut: Sign this message to confirm ownership of your wallet. Timestamp: ${new Date().toISOString()}`;
  
      try {
        // Request the user to sign the message
        const userSignature = await web3.eth.personal.sign(message, userAccount, '');
        console.log("Signature received:", userSignature);
  
        // Store account and signature if the user signs
        setAccount(userAccount);
        setSignature(userSignature);
  
        // Save account and signature to localStorage for persistence
        localStorage.setItem('walletAddress', userAccount);
        localStorage.setItem('walletSignature', userSignature);
      } catch (error) {
        console.error("User declined to sign the message:", error);
        // Cancel connection if the user does not sign
        setAccount(null);
        setSignature(null);
        localStorage.removeItem('walletAddress');
        localStorage.removeItem('walletSignature');
      }
    } catch (error) {
      console.error("Error during wallet connection:", error);
      setAccount(null);
      localStorage.removeItem('walletAddress');
    }
  };  

  return (
    <Router>
      <Navigation web3={web3} account={account} connectWallet={connectWallet} />
      <Routes>
        <Route path="/" element={<Home account={account} />} />
        <Route path="/about" element={<About />} />
        <Route path="/account" element={<Account account={account} />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </Router>
  );
};

export default App;
