// src/components/Home.js
import React from 'react';
import '../styles/styles.css';
import slugs from '../data/collectionSlugs';
import CollectionsTable from './CollectionsTable';

const Home = () => {
  return (
    <div className="container">
      <div className="page-content">
        <h1 className="page-title">Collections</h1>
        <CollectionsTable slugs={slugs} />
      </div>
    </div>
  );
};

export default Home;
