import React, { useEffect, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import logoEthereum from '../assets/logo-ethereum.png';
import logoMatic from '../assets/logo-matic.png';
import logoOpensea from '../assets/logo-opensea.png';
import collectionSlugs from '../data/collectionSlugs'; // Categories for filtering
import '../styles/styles.css';

const CollectionsTable = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All'); // Default to show all categories
  const [loading, setLoading] = useState(true); // Loading state for spinner
  const [filtersOpen, setFiltersOpen] = useState(false); // State to manage filters visibility

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  const closeFilters = () => {
    setFiltersOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setFiltersOpen(false); // Close the filters on desktop view
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formatNumber = (num) => {
    if (typeof num !== 'number' || isNaN(num)) {
      return 'N/A'; // Return a fallback value if num is not a valid number
    }
    return num >= 1 ? num.toFixed(0) : num.toFixed(2);
  };

  const getCategoryMapping = () => {
    const mapping = {};
    collectionSlugs.forEach((categoryData) => {
      categoryData.slugs.forEach((slug) => {
        mapping[slug] = categoryData.category; // Map slug to category
      });
    });
    return mapping;
  };

  useEffect(() => {
    const fetchData = async () => {
      console.log('Fetching cached data from the proxy...');
      setLoading(true);
      try {
        const response = await fetch('/api/collections');
        if (!response.ok) {
          throw new Error(`Failed to fetch cached data: ${response.statusText}`);
        }
        const collections = await response.json();

        // Generate the category mapping
        const categoryMapping = getCategoryMapping();

        // Transform the data and normalize categories
        const transformedData = collections.map((collection) => {
          const floorPrice = parseFloat(collection.stats?.total?.floor_price || 0);
          const totalSupply = parseInt(collection.total_supply || 0, 10);
          const calculatedMarketCap = floorPrice * totalSupply;

          return {
            category: categoryMapping[collection.collection] || 'Uncategorized',
            collection: collection.name || 'Unknown Collection',
            imageUrl: collection.image_url || 'https://via.placeholder.com/150',
            totalOwners: formatNumber(collection.stats?.total?.num_owners || 0),
            totalSupply: formatNumber(totalSupply),
            totalVolume: formatNumber(collection.stats?.total?.volume || 0),
            marketCap: formatNumber(collection.stats?.total?.market_cap || calculatedMarketCap),
            floorPrice: formatNumber(floorPrice),
            contractAddress: collection.contracts?.[0]?.address || 'N/A',
            chain: collection.contracts?.[0]?.chain || 'Unknown',
            openseaUrl: collection.opensea_url || '#',
            slug: collection.collection || 'unknown-slug',
          };
        });

        console.log('Transformed data with normalized categories:', transformedData);
        setData(transformedData);
      } catch (error) {
        console.error('Error fetching cached data:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCategory === 'All') {
      setFilteredData(data);
    } else {
      setFilteredData(data.filter((item) => item.category === selectedCategory));
    }
  }, [data, selectedCategory]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Collection',
        accessor: 'collection',
        Cell: ({ row }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={row.original.imageUrl} alt={row.original.collection} style={{ width: '40px', marginRight: '10px' }} />
            <a href={row.original.openseaUrl} target="_blank" rel="noopener noreferrer">
              {row.original.collection}
            </a>
          </div>
        ),
      },
      {
        Header: 'Market Cap (ETH)',
        accessor: 'marketCap',
      },
      {
        Header: 'Total Supply',
        accessor: 'totalSupply',
      },
      {
        Header: 'Floor Price (ETH)',
        accessor: 'floorPrice',
      },
      {
        Header: 'Total Volume (ETH)',
        accessor: 'totalVolume',
      },
      {
        Header: 'Total Owners',
        accessor: 'totalOwners',
      },
      {
        Header: 'Smart Contract',
        accessor: 'contractAddress',
        Cell: ({ row }) => {
          const chainUrls = {
            ethereum: 'https://etherscan.io/address/',
            matic: 'https://explorer-mainnet.maticvigil.com/address/',
          };
          return (
            <div style={{ textAlign: 'center' }}>
              <a href={`${chainUrls[row.original.chain]}${row.original.contractAddress}`} target="_blank" rel="noopener noreferrer">
                <img src={row.original.chain === 'ethereum' ? logoEthereum : logoMatic} alt={row.original.chain} style={{ width: '30px' }} />
              </a>
            </div>
          );
        },
      },
      {
        Header: 'OpenSea',
        accessor: 'slug',
        Cell: ({ value }) => (
          <a href={`https://opensea.io/collection/${value}`} target="_blank" rel="noopener noreferrer" style={{ textAlign: 'center' }}>
            <img src={logoOpensea} alt="OpenSea" style={{ width: '30px' }} />
          </a>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data: filteredData, // Use filtered data here
      initialState: {
        sortBy: [{ id: 'marketCap', desc: true }],
      },
    },
    useSortBy
  );

  return (
    <div className="collections-container">
      {/* Button outside the overlay */}
      {!filtersOpen && (
        <button className="filters-toggle" onClick={toggleFilters}>
          ✦ Filters ✦
        </button>
      )}
      <div className={`filters-overlay ${filtersOpen ? 'active' : 'hidden'}`}>
        {/* Close button inside the overlay */}
        <button className="filters-close" onClick={closeFilters}>
          ✕
        </button>
        <div className="filters">
          {/* Add the "All" button */}
          <button
            onClick={() => {
              setSelectedCategory('All');
              closeFilters();
            }}
            className={selectedCategory === 'All' ? 'active' : ''}
          >
            All
          </button>
          {collectionSlugs.map((categoryData, index) => (
            <button
              key={index}
              onClick={() => {
                setSelectedCategory(categoryData.category);
                closeFilters();
              }}
              className={selectedCategory === categoryData.category ? 'active' : ''}
            >
              {categoryData.category}
            </button>
          ))}
        </div>

      </div>
      {loading ? (
        <div className="spinner">Loading...</div>
      ) : (
        <div className="table-container">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );  
};

export default CollectionsTable;
