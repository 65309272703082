import React from 'react';
import { createRoot } from 'react-dom/client';
import { WalletProvider } from './utils/walletContext';
import App from './App'; // Use App as the root component

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <WalletProvider>
      <App />
    </WalletProvider>
  </React.StrictMode>
);