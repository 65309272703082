import React, { useEffect, useState } from 'react';
import '../styles/styles.css'; 
import '../styles/Account.css'; 
import '../styles/Navigation.css';
import axios from 'axios';

const Account = ({ account }) => {
  const [nftData, setNftData] = useState([]);
  const [collectionsData, setCollectionsData] = useState([]); // Cached collections data
  const [loading, setLoading] = useState(true); // Loading state for data fetching

  const formatNumber = (num) => {
    if (typeof num !== 'number' || isNaN(num)) {
      return 'N/A'; // Return a fallback value if num is not a valid number
    }
    return num >= 1 ? num.toFixed(0) : num.toFixed(2);
  };

  useEffect(() => {
    const fetchCollectionsData = async () => {
      try {
        const response = await axios.get('/api/collections');
        const transformedData = response.data.map((collection) => ({
          collection: collection.name || 'Unknown Collection',
          imageUrl: collection.image_url || 'https://via.placeholder.com/150',
          floorPrice: formatNumber(collection.stats?.total?.floor_price || 0),
          slug: collection.collection || 'unknown-slug',
        }));
        setCollectionsData(transformedData);
      } catch (error) {
        console.error('Error fetching collections data:', error);
      }
    };

    fetchCollectionsData();
  }, []);

  useEffect(() => {
    const fetchNftData = async () => {
      if (account) {
        setLoading(true);
        try {
          const response = await axios.get(`/api/nfts/${account}`);
          const groupedData = response.data.reduce((acc, nft) => {
            acc[nft.collection] = acc[nft.collection] || { collection: nft.collection, count: 0 };
            acc[nft.collection].count += 1;
            return acc;
          }, {});
          setNftData(Object.values(groupedData));
        } catch (error) {
          console.error('Error fetching NFT data:', error);
          setNftData([]);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchNftData();
  }, [account]);

  const calculateTotal = (floorPrice, count) => (floorPrice * count).toFixed(2);

  const renderCollectionDetails = (collectionSlug) => {
    const collection = collectionsData.find((col) => col.slug === collectionSlug) || {};
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={collection.imageUrl}
          alt={`${collection.collection} thumbnail`}
          style={{ width: '40px', marginRight: '10px' }}
        />
        <span>{collection.collection}</span>
      </div>
    );
  };

  const totalNFTs = nftData.reduce((sum, nft) => sum + nft.count, 0);
  const totalValue = nftData.reduce((sum, nft) => {
    const collection = collectionsData.find((col) => col.slug === nft.collection) || {};
    return sum + parseFloat(collection.floorPrice || 0) * nft.count;
  }, 0).toFixed(2);

  return (
    <div className="container">
      <div className="page-content">
        <h1 className="page-title">My Account</h1>
        {account ? (
          loading ? (
            <p>Fetching...</p>
          ) : (
            nftData && nftData.length > 0 ? (
              <table className="nft-table">
                <thead>
                  <tr>
                    <th>Collection</th>
                    <th>Floor Price</th>
                    <th>Number of NFTs</th>
                    <th>Total Value</th>
                  </tr>
                </thead>
                <tbody>
                  {nftData.map((nft) => {
                    const collection = collectionsData.find((col) => col.slug === nft.collection) || {};
                    return (
                      <tr key={nft.collection}>
                        <td>{renderCollectionDetails(nft.collection)}</td>
                        <td>{collection.floorPrice || 'N/A'} ETH</td>
                        <td>{nft.count}</td>
                        <td>{calculateTotal(parseFloat(collection.floorPrice) || 0, nft.count)} ETH</td>
                      </tr>
                    );
                  })}
                  <tr className="total-row">
                    <td></td>
                    <td></td>
                    <td><strong>{totalNFTs}</strong></td>
                    <td><strong>{totalValue} ETH</strong></td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <p>No Genesis NFTs found!</p>
            )
          )
        ) : (
          <p>Sign in to access your account.</p>
        )}
      </div>
    </div>
  );
};

export default Account;
