// src/data/collectionSlugs.js
const slugs = [
  {
    category: "Brands",
    slugs: [
      "alts-by-adidas",
      "budverse-cans-heritage-edition",
      "clonex",
      "dolce-gabbana-dgfamily",
      "lacoste-undw3-card",
      "mclarenmsolabgenesis",
      "pepsi-mic-drop",
      "pradatimecapsule",
      "volcom-metaboard",
      "blvckgenesis"
    ],
  },
  {
    category: "Music",
    slugs: [
      "grimes-x-mac",
      "kings-of-leon-yellowheart-nfts",
      "muse-serenade",
      "snoopdoggbodr",
      "tory",
      "the-weeknd-x-billboard-by-autograph"
    ],
  },
  {
    category: "Sports",
    slugs: [
      "genesis-curry-flow",
      "leo-messi-magician",
      "floyd-mayweather-autograph-collection"
    ],
  },
  {
    category: "Digital Art",
    slugs: [
      "beeple-everydays", 
      "murakami-flowers-2022-official",
      "fewocious",
      "lostpoets",
      "hackatao",
      "slimesunday",
      "xcopy-editions"
    ],
  },
  {
    category: "Crypto",
    slugs: [
      "astrobot-society-by-token-metrics",
      "nfteye-lifetime",
      "ledger-market-pass-genesis-edition",
      "premint-collector",
    ],
  },
];

module.exports = slugs; // Export using CommonJS syntax
