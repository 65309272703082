// formatAddress.js

/**
 * Shortens an Ethereum address for display.
 * @param {string} address - The full Ethereum address.
 * @returns {string} The shortened address (e.g., 0x123...456).
 */
export const shortenAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };
  
  /**
   * Resolves an ENS name for an Ethereum address, if it exists.
   * @param {Object} ens - An initialized ENS instance from web3-eth-ens.
   * @param {string} address - The Ethereum address to resolve.
   * @returns {Promise<string>} The ENS name or the shortened address.
   */
/**
 * Resolves an ENS name for an Ethereum address using the ENS Public API.
 * @param {string} address - The Ethereum address to resolve.
 * @returns {Promise<string>} The ENS name or the shortened address.
 */
  export const resolveEnsName = async (address) => {
    if (!address) return shortenAddress(address);
  
    try {
      const response = await fetch(`https://api.ensideas.com/ens/resolve/${address}`);
      if (!response.ok) {
        throw new Error(`ENS API returned status: ${response.status}`);
      }
      const data = await response.json();
      if (data?.name) {
        return data.name; // Return the resolved ENS name
      }
    } catch (error) {
      console.error('Error resolving ENS name:', error);
    }
  
    return shortenAddress(address); // Fallback to shortened address
  };
  
  